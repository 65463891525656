// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../../libs/ID.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Table from "../../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Toggle from "../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../styleguide/icons/IconMenu.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as TableRow from "../../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as TableBody from "../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../../styleguide/icons/IconSorting.res.js";
import * as SearchField from "../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../../styleguide/components/Table/TableHeader.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as TableHeaderCell from "../../../../styleguide/components/Table/TableHeaderCell.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_ProviderBrochure from "../../../../api/provider-brochures/Api_ProviderBrochure.res.js";
import * as Routes_ProviderBrochure from "../../../../routes/common/Routes_ProviderBrochure.res.js";
import * as ProvidersDashboardNavbar from "../../../providers/dashboard/index/components/navbar/ProvidersDashboardNavbar.res.js";
import * as ProviderBrochuresDashboardScss from "./ProviderBrochuresDashboard.scss";
import * as ProviderBrochuresDashboardOptions from "./components/options/ProviderBrochuresDashboardOptions.res.js";
import * as ProviderBrochuresDashboardActionsBar from "./components/actions-bar/ProviderBrochuresDashboardActionsBar.res.js";
import * as ProviderBrochuresDashboardProviderFilter from "./components/provider-filter/ProviderBrochuresDashboardProviderFilter.res.js";

var css = ProviderBrochuresDashboardScss;

var initialState_sortBy = {
  NAME: "Title",
  VAL: "Asc"
};

var initialState_selection = ID.$$Set.make();

var initialState_entityUpdates = ID.$$Map.make();

var initialState_providerIds = [];

var initialState = {
  status: "FetchingProviderBrochures",
  currentPage: 1,
  totalPages: 1,
  sortBy: initialState_sortBy,
  search: "",
  selection: initialState_selection,
  entityUpdates: initialState_entityUpdates,
  batchUpdate: undefined,
  batchUpdateError: false,
  openDestroyConfirm: false,
  currentProviderBrochureId: undefined,
  providerIds: initialState_providerIds
};

function ProviderBrochuresDashboard$default(props) {
  var container = React.useRef(null);
  var searchProviderBrochures = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "RemoveProviderBrochure" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              param.dispatch("FetchProviderBrochures");
                            })
                        };
              case "FetchProviderBrochures" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api_ProviderBrochure.dashboard(state.currentPage, tmp, state.sortBy, state.providerIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedProviderBrochuresFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ProviderBrochuresDashboard",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "ProviderBrochuresDashboard.default"
                                          }, "ProviderBrochuresDashboard::FetchProviderBrochures::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailProviderBrochuresFetch");
                                    }));
                            })
                        };
              case "FailProviderBrochuresFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedProviderBrochuresFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentProviderBrochureId: state.currentProviderBrochureId,
                            providerIds: state.providerIds
                          }
                        };
              case "ToggleSelectionOfAllItems" :
                  var match = state.status;
                  var match$1 = state.batchUpdate;
                  if (typeof match !== "object") {
                    return "NoUpdate";
                  }
                  if (match$1 !== undefined) {
                    return "NoUpdate";
                  }
                  var locations = match._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: locations.length !== Belt_Set.size(state.selection) ? Belt_Set.fromArray(Belt_Array.map(locations, (function ($$location) {
                                          return $$location.id;
                                        })), ID.Comparable) : ID.$$Set.make(),
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentProviderBrochureId: state.currentProviderBrochureId,
                            providerIds: state.providerIds
                          }
                        };
              case "DeselectAll" :
                  var match$2 = state.status;
                  var match$3 = state.batchUpdate;
                  if (typeof match$2 !== "object" || match$3 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentProviderBrochureId: state.currentProviderBrochureId,
                              providerIds: state.providerIds
                            }
                          };
                  }
              case "ActivateInBatch" :
                  var providerBrochures = state.status;
                  if (typeof providerBrochures !== "object") {
                    return "NoUpdate";
                  }
                  var providerBrochures$1 = providerBrochures._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Activating",
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentProviderBrochureId: state.currentProviderBrochureId,
                            providerIds: state.providerIds
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var providerBrochureIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function (providerBrochure) {
                                                          return Caml_obj.equal(id, providerBrochure.id);
                                                        }), __x);
                                          })(providerBrochures$1);
                                      if (match !== undefined && !match.active) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api_ProviderBrochure.activateInBatch({
                                        providerBrochureIds: providerBrochureIds,
                                        active: true
                                      }), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedActivateInBatch",
                                                    _0: providerBrochureIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ProviderBrochuresDashboard",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "ProviderBrochuresDashboard.default"
                                          }, "ProviderBrochuresDashboard" + "::ActivateInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "DeactivateInBatch" :
                  var providerBrochures$2 = state.status;
                  if (typeof providerBrochures$2 !== "object") {
                    return "NoUpdate";
                  }
                  var providerBrochures$3 = providerBrochures$2._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Deactivating",
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentProviderBrochureId: state.currentProviderBrochureId,
                            providerIds: state.providerIds
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var providerBrochureIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function (providerBrochure) {
                                                          return Caml_obj.equal(id, providerBrochure.id);
                                                        }), __x);
                                          })(providerBrochures$3);
                                      if (match !== undefined && match.active) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api_ProviderBrochure.activateInBatch({
                                        providerBrochureIds: providerBrochureIds,
                                        active: false
                                      }), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedDeactivateInBatch",
                                                    _0: providerBrochureIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ProviderBrochuresDashboard",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "ProviderBrochuresDashboard.default"
                                          }, "ProviderBrochuresDashboard" + "::DeactivateInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "FailInBatchAction" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: undefined,
                            batchUpdateError: true,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentProviderBrochureId: state.currentProviderBrochureId,
                            providerIds: state.providerIds
                          }
                        };
              case "CloseDestroyConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: false,
                            currentProviderBrochureId: undefined,
                            providerIds: state.providerIds
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: "",
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentProviderBrochureId: state.currentProviderBrochureId,
                            providerIds: []
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProviderBrochures");
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedProviderBrochuresFetch" :
                  var res = action._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.providerBrochures
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentProviderBrochureId: state.currentProviderBrochureId,
                            providerIds: state.providerIds
                          }
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match$4 = state.status;
                  if (typeof match$4 !== "object" && match$4 === "FetchingProviderBrochures") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingProviderBrochures",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentProviderBrochureId: state.currentProviderBrochureId,
                              providerIds: state.providerIds
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProviderBrochures");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var sort = action._0;
                  var match$5 = state.status;
                  if (typeof match$5 !== "object" && match$5 === "FetchingProviderBrochures") {
                    return "NoUpdate";
                  }
                  var tmp;
                  if (sort === "Active") {
                    var match$6 = state.sortBy;
                    tmp = typeof match$6 === "object" && match$6.NAME === "Active" && match$6.VAL === "Desc" ? ({
                          NAME: "Active",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Active",
                          VAL: "Desc"
                        });
                  } else if (sort === "ProviderName") {
                    var match$7 = state.sortBy;
                    tmp = typeof match$7 === "object" && match$7.NAME === "ProviderName" && match$7.VAL === "Desc" ? ({
                          NAME: "ProviderName",
                          VAL: "Asc"
                        }) : ({
                          NAME: "ProviderName",
                          VAL: "Desc"
                        });
                  } else if (sort === "Downloads") {
                    var match$8 = state.sortBy;
                    tmp = typeof match$8 === "object" && match$8.NAME === "Downloads" && match$8.VAL === "Desc" ? ({
                          NAME: "Downloads",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Downloads",
                          VAL: "Desc"
                        });
                  } else if (sort === "Title") {
                    var match$9 = state.sortBy;
                    tmp = typeof match$9 === "object" && match$9.NAME === "Title" && match$9.VAL === "Desc" ? ({
                          NAME: "Title",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Title",
                          VAL: "Desc"
                        });
                  } else {
                    var match$10 = state.sortBy;
                    tmp = typeof match$10 === "object" && match$10.NAME === "Views" && match$10.VAL === "Desc" ? ({
                          NAME: "Views",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Views",
                          VAL: "Desc"
                        });
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingProviderBrochures",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: tmp,
                            search: state.search,
                            selection: ID.$$Set.make(),
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentProviderBrochureId: state.currentProviderBrochureId,
                            providerIds: state.providerIds
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProviderBrochures");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentProviderBrochureId: state.currentProviderBrochureId,
                            providerIds: state.providerIds
                          },
                          _1: searchProviderBrochures
                        };
              case "PerformSearch" :
                  var match$11 = state.status;
                  if (typeof match$11 !== "object" && match$11 === "FetchingProviderBrochures") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingProviderBrochures",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentProviderBrochureId: state.currentProviderBrochureId,
                              providerIds: state.providerIds
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProviderBrochures");
                              })
                          };
                  }
              case "ToggleSelectionOfItem" :
                  var id = action._0;
                  var match$12 = state.batchUpdate;
                  if (match$12 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: Belt_Set.has(state.selection, id) ? Belt_Set.remove(state.selection, id) : Belt_Set.add(state.selection, id),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentProviderBrochureId: state.currentProviderBrochureId,
                              providerIds: state.providerIds
                            }
                          };
                  }
              case "UpdateActive" :
                  var action$1 = action._1;
                  var providerBrochureId = action._0;
                  var match$13 = state.status;
                  var match$14 = state.batchUpdate;
                  var match$15 = Belt_Map.get(state.entityUpdates, providerBrochureId);
                  if (typeof match$13 !== "object") {
                    match$13 === "FetchingProviderBrochures";
                  } else if (match$14 === undefined && match$15 === undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(match$13._0, (function (x) {
                                        if (!Caml_obj.equal(x.id, providerBrochureId)) {
                                          return x;
                                        }
                                        var tmp;
                                        tmp = action$1 === "Active" ? true : false;
                                        return {
                                                id: x.id,
                                                providerId: x.providerId,
                                                providerName: x.providerName,
                                                providerSlug: x.providerSlug,
                                                title: x.title,
                                                subtitle: x.subtitle,
                                                description: x.description,
                                                image: x.image,
                                                slug: x.slug,
                                                pdfFile: x.pdfFile,
                                                privateDownload: x.privateDownload,
                                                active: tmp,
                                                metaTitle: x.metaTitle,
                                                metaDescription: x.metaDescription,
                                                pdfResource: x.pdfResource,
                                                downloadsCount: x.downloadsCount,
                                                ahoyEventsCount: x.ahoyEventsCount,
                                                createdAt: x.createdAt,
                                                updatedAt: x.updatedAt
                                              };
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: state.selection,
                              entityUpdates: Belt_Map.set(state.entityUpdates, providerBrochureId, {
                                    TAG: "ActiveUpdate",
                                    _0: action$1
                                  }),
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: state.batchUpdateError,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentProviderBrochureId: state.currentProviderBrochureId,
                              providerIds: state.providerIds
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                if (action$1 === "Active") {
                                  return $$Promise.wait(Api_ProviderBrochure.activate(providerBrochureId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedActiveUpdate",
                                                              _0: providerBrochureId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "ProviderBrochuresDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "ProviderBrochuresDashboard.default"
                                                    }, "ProviderBrochuresDashboard" + "::Activate::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailActiveUpdate",
                                                      _0: providerBrochureId
                                                    });
                                              }));
                                } else {
                                  return $$Promise.wait(Api_ProviderBrochure.deactivate(providerBrochureId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedActiveUpdate",
                                                              _0: providerBrochureId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "ProviderBrochuresDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "ProviderBrochuresDashboard.default"
                                                    }, "ProviderBrochuresDashboard" + "::Deactivate::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailActiveUpdate",
                                                      _0: providerBrochureId
                                                    });
                                              }));
                                }
                              })
                          };
                  }
                  return "NoUpdate";
              case "SucceedActiveUpdate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, action._0),
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentProviderBrochureId: state.currentProviderBrochureId,
                            providerIds: state.providerIds
                          }
                        };
              case "FailActiveUpdate" :
                  var providerBrochureId$1 = action._0;
                  var match$16 = state.status;
                  var match$17 = Belt_Map.get(state.entityUpdates, providerBrochureId$1);
                  if (typeof match$16 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$17 === undefined) {
                    return "NoUpdate";
                  }
                  var action$2 = match$17._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: Belt_Array.map(match$16._0, (function (x) {
                                      if (!Caml_obj.equal(x.id, providerBrochureId$1)) {
                                        return x;
                                      }
                                      var tmp;
                                      tmp = action$2 === "Active" ? true : false;
                                      return {
                                              id: x.id,
                                              providerId: x.providerId,
                                              providerName: x.providerName,
                                              providerSlug: x.providerSlug,
                                              title: x.title,
                                              subtitle: x.subtitle,
                                              description: x.description,
                                              image: x.image,
                                              slug: x.slug,
                                              pdfFile: x.pdfFile,
                                              privateDownload: x.privateDownload,
                                              active: tmp,
                                              metaTitle: x.metaTitle,
                                              metaDescription: x.metaDescription,
                                              pdfResource: x.pdfResource,
                                              downloadsCount: x.downloadsCount,
                                              ahoyEventsCount: x.ahoyEventsCount,
                                              createdAt: x.createdAt,
                                              updatedAt: x.updatedAt
                                            };
                                    }))
                            },
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, providerBrochureId$1),
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentProviderBrochureId: state.currentProviderBrochureId,
                            providerIds: state.providerIds
                          }
                        };
              case "SucceedActivateInBatch" :
                  var ids = action._0;
                  var locations$1 = state.status;
                  if (typeof locations$1 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(locations$1._0, (function (x) {
                                        var id = x.id;
                                        if ((function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(ids)) {
                                          return {
                                                  id: x.id,
                                                  providerId: x.providerId,
                                                  providerName: x.providerName,
                                                  providerSlug: x.providerSlug,
                                                  title: x.title,
                                                  subtitle: x.subtitle,
                                                  description: x.description,
                                                  image: x.image,
                                                  slug: x.slug,
                                                  pdfFile: x.pdfFile,
                                                  privateDownload: x.privateDownload,
                                                  active: true,
                                                  metaTitle: x.metaTitle,
                                                  metaDescription: x.metaDescription,
                                                  pdfResource: x.pdfResource,
                                                  downloadsCount: x.downloadsCount,
                                                  ahoyEventsCount: x.ahoyEventsCount,
                                                  createdAt: x.createdAt,
                                                  updatedAt: x.updatedAt
                                                };
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentProviderBrochureId: state.currentProviderBrochureId,
                              providerIds: state.providerIds
                            }
                          };
                  }
              case "SucceedDeactivateInBatch" :
                  var ids$1 = action._0;
                  var locations$2 = state.status;
                  if (typeof locations$2 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(locations$2._0, (function (x) {
                                        var id = x.id;
                                        if ((function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(ids$1)) {
                                          return {
                                                  id: x.id,
                                                  providerId: x.providerId,
                                                  providerName: x.providerName,
                                                  providerSlug: x.providerSlug,
                                                  title: x.title,
                                                  subtitle: x.subtitle,
                                                  description: x.description,
                                                  image: x.image,
                                                  slug: x.slug,
                                                  pdfFile: x.pdfFile,
                                                  privateDownload: x.privateDownload,
                                                  active: false,
                                                  metaTitle: x.metaTitle,
                                                  metaDescription: x.metaDescription,
                                                  pdfResource: x.pdfResource,
                                                  downloadsCount: x.downloadsCount,
                                                  ahoyEventsCount: x.ahoyEventsCount,
                                                  createdAt: x.createdAt,
                                                  updatedAt: x.updatedAt
                                                };
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentProviderBrochureId: state.currentProviderBrochureId,
                              providerIds: state.providerIds
                            }
                          };
                  }
              case "OpenDestroyConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: true,
                            currentProviderBrochureId: Caml_option.some(action._0),
                            providerIds: state.providerIds
                          }
                        };
              case "DestroyProviderBrochure" :
                  var id$1 = action._0;
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_ProviderBrochure.destroy(id$1), (function (x) {
                                      if (x.TAG === "Ok") {
                                        dispatch("CloseDestroyConfirm");
                                        return dispatch({
                                                    TAG: "SucceedProviderBrochuresFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ProviderBrochuresDashboard",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "ProviderBrochuresDashboard.default"
                                          }, "Destroy::FetchProviderBrochures::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailProviderBrochuresFetch");
                                    }));
                            })
                        };
              case "UpdateProviderIds" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentProviderBrochureId: state.currentProviderBrochureId,
                            providerIds: action._0
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProviderBrochures");
                            })
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchProviderBrochures");
        }), []);
  var locations = state.status;
  var tmp;
  if (typeof locations !== "object") {
    tmp = false;
  } else {
    var locations$1 = locations._0;
    tmp = Belt_Set.size(state.selection) === locations$1.length && locations$1.length !== 0;
  }
  var match$1 = state.sortBy;
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var match$5 = state.sortBy;
  var providerBrochures = state.status;
  var tmp$1;
  if (typeof providerBrochures !== "object") {
    tmp$1 = providerBrochures === "FetchingProviderBrochures" ? JsxRuntime.jsx(TableRow.make, {
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Spinner.make, {
                              size: "XL",
                              color: "Teal"
                            }),
                        className: css.spinner
                      })
                })
          }) : JsxRuntime.jsx(TableRow.make, {
            className: css.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "Something went wrong during the search."
                })
          });
  } else {
    var providerBrochures$1 = providerBrochures._0;
    tmp$1 = providerBrochures$1.length !== 0 ? Belt_Array.mapWithIndex(providerBrochures$1, (function (index, providerBrochure) {
              var active = providerBrochure.active;
              return JsxRuntime.jsxs(TableRow.make, {
                          children: [
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx(Checkbox.make, {
                                        id: "providerBrochure--" + (ID.toString(providerBrochure.id) + "--selected"),
                                        size: "SM",
                                        checked: Belt_Set.has(state.selection, providerBrochure.id),
                                        onChange: (function (param) {
                                            dispatch({
                                                  TAG: "ToggleSelectionOfItem",
                                                  _0: providerBrochure.id
                                                });
                                          })
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: providerBrochure.title,
                                        className: css.tableLink,
                                        href: Routes_ProviderBrochure.Dashboard.edit(providerBrochure.id)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: providerBrochure.providerName,
                                        className: css.tableLink,
                                        href: Routes_Provider.Dashboard.edit(providerBrochure.providerSlug)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: String(providerBrochure.downloadsCount)
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: String(providerBrochure.ahoyEventsCount)
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx(Toggle.make, {
                                        on: active,
                                        size: "MD",
                                        onChange: (function () {
                                            dispatch({
                                                  TAG: "UpdateActive",
                                                  _0: providerBrochure.id,
                                                  _1: active ? "Inactive" : "Active"
                                                });
                                          })
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs(Popover.make, {
                                              children: [
                                                JsxRuntime.jsx(Popover.Trigger.make, {
                                                      children: JsxRuntime.jsx(Control.make, {
                                                            children: JsxRuntime.jsx(IconMenu.make, {
                                                                  size: "MD",
                                                                  color: "Teal"
                                                                })
                                                          })
                                                    }),
                                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                                      position: {
                                                        TAG: "Below",
                                                        _0: "RightEdge"
                                                      },
                                                      className: css.dropdownBody,
                                                      children: [
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: css.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "View",
                                                                    className: css.dropdownBodyLink,
                                                                    href: Routes_ProviderBrochure.show(providerBrochure.slug)
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: css.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Edit",
                                                                    className: css.dropdownBodyLink,
                                                                    href: Routes_ProviderBrochure.Dashboard.edit(providerBrochure.id)
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: css.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Download",
                                                                    className: css.dropdownBodyLink,
                                                                    href: providerBrochure.privateDownload
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: css.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Delete",
                                                                    className: css.dropdownBodyLink,
                                                                    href: "#",
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "OpenDestroyConfirm",
                                                                              _0: providerBrochure.id
                                                                            });
                                                                      })
                                                                  })
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        className: css.blogPostMenu
                                      })
                                })
                          ]
                        }, String(index));
            })) : JsxRuntime.jsx(TableRow.make, {
            className: css.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "No provider brochures were found."
                })
          });
  }
  var providerBrochures$2 = state.status;
  var tmp$2;
  if (typeof providerBrochures$2 !== "object") {
    tmp$2 = null;
  } else {
    var providerBrochures$3 = providerBrochures$2._0;
    tmp$2 = JsxRuntime.jsx(ProviderBrochuresDashboardActionsBar.make, {
          selectionCount: Belt_Set.toArray(state.selection).length,
          activeSelection: Belt_Array.reduce(Belt_Array.map(Belt_Set.toArray(state.selection), (function (id) {
                      return Belt_Option.map((function (__x) {
                                      return Js_array.find((function (providerBrochure) {
                                                    return Caml_obj.equal(id, providerBrochure.id);
                                                  }), __x);
                                    })(providerBrochures$3), (function (providerBrochure) {
                                    return providerBrochure.active;
                                  }));
                    })), [], (function (acc, active) {
                  if (active !== undefined) {
                    return Belt_Array.concat(acc, [active]);
                  } else {
                    return acc;
                  }
                })),
          error: state.batchUpdateError,
          busy: Belt_Option.isSome(state.batchUpdate),
          deselect: (function () {
              dispatch("DeselectAll");
            }),
          activate: (function (param) {
              dispatch("ActivateInBatch");
            }),
          deactivate: (function (param) {
              dispatch("DeactivateInBatch");
            })
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                state.openDestroyConfirm ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Are you sure?",
                                      className: css.deleteConfirmationText
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Gray",
                                              onClick: (function (param) {
                                                  dispatch("CloseDestroyConfirm");
                                                }),
                                              children: "Cancel"
                                            }),
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Pink",
                                              onClick: (function (param) {
                                                  var providerBrochureId = state.currentProviderBrochureId;
                                                  if (providerBrochureId !== undefined) {
                                                    return $$Promise.wait(Api_ProviderBrochure.destroy(Caml_option.valFromOption(providerBrochureId)), (function (x) {
                                                                  if (x.TAG === "Ok") {
                                                                    return Url.visit(Routes_ProviderBrochure.Dashboard.index);
                                                                  } else {
                                                                    return SentryLogger.error1({
                                                                                rootModule: "ProviderBrochuresDashboard",
                                                                                subModulePath: /* [] */0,
                                                                                value: "default",
                                                                                fullPath: "ProviderBrochuresDashboard.default"
                                                                              }, "DestroyProviderBrochure::Error", [
                                                                                "Error",
                                                                                x._0
                                                                              ]);
                                                                  }
                                                                }));
                                                  } else {
                                                    return dispatch("CloseDestroyConfirm");
                                                  }
                                                }),
                                              children: "Yes, delete it"
                                            })
                                      ],
                                      className: css.deleteConfirmationButtons
                                    })
                              ],
                              className: css.deleteConfirmationDialog
                            }),
                        className: css.deleteConfirmationOverlay,
                        onClick: (function ($$event) {
                            $$event.stopPropagation();
                          })
                      }) : null,
                JsxRuntime.jsx(ProvidersDashboardNavbar.make, {
                      selectedCategory: "Brochures"
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: css.layout,
                      setRef: Caml_option.some(container),
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(H1.make, {
                                    className: css.title,
                                    children: "Manage Provider Brochures"
                                  }),
                              className: css.header
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(SearchField.make, {
                                      id: "location-brochures-search",
                                      value: state.search,
                                      placeholder: "Search by title, provider.",
                                      inputClassName: css.searchField,
                                      onChange: (function ($$event) {
                                          dispatch({
                                                TAG: "UpdateSearchInput",
                                                _0: $$event.target.value
                                              });
                                        })
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(ProviderBrochuresDashboardProviderFilter.make, {
                                              updateProviderIds: (function (ids) {
                                                  return function (param) {
                                                    dispatch({
                                                          TAG: "UpdateProviderIds",
                                                          _0: ids
                                                        });
                                                  };
                                                }),
                                              reset: (function () {
                                                  dispatch("Reset");
                                                })
                                            }),
                                        JsxRuntime.jsx(ProviderBrochuresDashboardOptions.make, {})
                                      ],
                                      className: css.headerControls
                                    })
                              ],
                              className: css.subHeader
                            }),
                        JsxRuntime.jsxs(Table.make, {
                              className: css.table,
                              children: [
                                JsxRuntime.jsx(TableHeader.make, {
                                      children: JsxRuntime.jsxs(TableRow.make, {
                                            children: [
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Checkbox.make, {
                                                          id: "providerBrochure--all--selected",
                                                          size: "SM",
                                                          checked: tmp,
                                                          onChange: (function (param) {
                                                              dispatch("ToggleSelectionOfAllItems");
                                                            })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "Title"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Brochure",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$1 === "object" && match$1.NAME === "Title" ? match$1.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "ProviderName"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Provider",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$2 === "object" && match$2.NAME === "ProviderName" ? match$2.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "Downloads"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Downloads",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$3 === "object" && match$3.NAME === "Downloads" ? match$3.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "Views"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Views",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$4 === "object" && match$4.NAME === "Views" ? match$4.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "Active"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Active",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$5 === "object" && match$5.NAME === "Active" ? match$5.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: ""
                                                  })
                                            ]
                                          })
                                    }),
                                JsxRuntime.jsx(TableBody.make, {
                                      children: tmp$1
                                    })
                              ]
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Pagination.make, {
                                    currentPage: state.currentPage,
                                    totalPages: state.totalPages,
                                    onPageClick: (function (x) {
                                        dispatch({
                                              TAG: "UpdatePage",
                                              _0: x
                                            });
                                      })
                                  }),
                              className: css.pagination
                            })
                      ]
                    }),
                tmp$2
              ]
            });
}

var $$default = ProviderBrochuresDashboard$default;

export {
  css ,
  initialState ,
  $$default as default,
}
/* css Not a pure module */
